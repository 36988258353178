<template>
	<div class="score__box" ref="score-box">
		<div v-for="(sport) in sports" :key="sport.id">
			<div class="score__box__title">
				<h2>{{ sport.menu }}</h2>
				<h3>{{ sport.submenu }}</h3>
			</div>
			<div v-for="game in getScoresBySubmenu(sport.id)" class="score__box__game" :key="game.id">
				<div class="score__box__game__title">
					<h3>{{ game.equipo[0].nombre }} - {{game.equipo[1].nombre}}</h3>
				</div>
				<div class="score__box__game__result">
					<h3><b>{{ game.resultado[0].tipo_resultado.nombre }}: </b>{{ game.event }}</h3>
				</div>
			</div>
		</div>

		<div v-if="scores.length == 0" class="score__box__title">
			<h2>No hay resultados para esta fecha</h2>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TheScore',
		data() {
			return {
				loaded: false,
			}
		},
		methods: {
			getScoresBySubmenu(submenu) {
				return this.scores.filter(score => score.subcategoria === submenu)
			}
		},
		computed: {
			sports() {
				let sports = []
				this.scores.forEach(score => {
					if(! sports.find(sport => sport.menu === score.menu.nombre && sport.submenu === score.submenu.nombre)) {						
						sports.push({ id: score.submenu.id, menu: score.menu.nombre, submenu: score.submenu.nombre })
					}					
				});
				return sports
			},
			scores() {
				return this.$store.state.Score.data ? this.$store.state.Score.data : false
			},
			loading() {
				return this.$store.getters.scoreLoading
			}
		},
		watch: {
			scores(val) {
				if (val) {
					let mobile = window.innerWidth < 768
					
					if (!mobile) {
						const el = this.$refs["score-box"];

						if (el) {
							el.scrollIntoView({behavior: 'smooth'});
						}						
					}
				}
			}
		},
	}
</script>

<style lang="scss" scoped>

	.score {	

		&__box {
			@include border-radius(0 0 70px 70px);
			background-color: $darkGray;
			z-index: 4;
			margin: 53.7% auto 0;
			width: 93%;
			padding-top: 20px;
			padding-bottom: 80px;
			
			&__title {
				background-image: url('../assets/img/score-title.png');
				background-repeat: no-repeat;
				background-size: 100%;
				padding: 36px 0 90px;

				* {
					@extend %bold;
					text-align: center;
				}

				h2 {
					font-size: 2.865vw;
					color: $darkGray;
					line-height: 1.6;
				}

				h3 {
					font-size: 2.865vw;
					text-shadow: 5.248px 6.038px 4.4px rgba(4, 2, 3, 0.47);
					color: $gray;
					letter-spacing: -1.5px;
				}
			}

			&__game {
				margin: -45px 10px 90px;

				&__title {
					background-image: url('../assets/img/teams-title.png');
					background-repeat: no-repeat;
					background-size: 100%;					
					padding-top: 18px;
					padding-bottom: 35px;
					padding-left: 55px;
					margin-bottom: 12px;
				}

				h3 {
					@extend %bold;
					font-size: 1.771vw;					
					color: $darkGray;
					letter-spacing: -.8px;
				}

				&__result {
					background-image: url('../assets/img/result-bar.png');	
					background-repeat: no-repeat;
					background-size: 100%;
					padding-top: 38px;
					padding-bottom: 36px;
					padding-left: 55px;									

					h3 {
						color: $white;

						b {
							color: $orangeTheme;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}

	@include desktopMax {

		.score {

			&__box {

				&__game {
					margin-bottom: 20px;

					&__title {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	@include tabletLandscape {

		.score {

			&__box {
				margin-top: 136%;
				padding-top: 2px;

				&__game {
					margin-bottom: 55px;

					&__title {
						padding-bottom: 20px;
					}

					&__result {
						padding-top: 12px;
					}
				}
			}
		}		
	}

	@include mobile {
		
		.score {

			&__box {
				width: 98%;
				margin-top: 136%;
				padding-top: 2px;
				padding-bottom: 30px;
				margin-bottom: 80px;

				&__title {
					padding-top: 9px;
					padding-bottom: 56px;

					h2, h3 {
						letter-spacing: normal;						
					}
				}

				&__game {
					margin-bottom: 50px;

					&:last-child {
						margin-bottom: 20px;
					}

					&__title {
						padding: 5px 10px;

						h3 {
							font-size: 7px;
						}
					}

					&__result {
						    padding: 6px 10px 10px;

						h3 {
							font-size: 7px;
						}
					}
				}
			}
		}	
	}	
</style>