<template>
	<div class="score" v-if="scores">
		<Promo 
			btn="Fecha" 
			btn-class="bigger black"
			:icon="icon"
		/>
		<transition appear name="bounce" enter-active-class="bounceInUp">

			<TheScore v-if="! loading"/>

		</transition>
	</div>
</template>

<script>
import Promo from '../components/Promo.vue'
import TheScore from '../components/TheScore.vue'
	export default {
		name: 'Score',
		title: 'Resultados',
		components: {
			Promo,
			TheScore,
		},
		data() {
			return {
				icon: '<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></svg>',
			}
		},
		created() {
			let yesterday = new Date(new Date().setDate(new Date().getDate()-1));
			this.$store.dispatch('getScores', yesterday.toLocaleDateString('fr-CA'))
		},
		computed: {
			scores() {
				return this.$store.state.Score.data ? this.$store.state.Score.data : false
			},
			loading() {
				return this.$store.state.Score.loading
			}
		},		
	}
</script>

<style lang="scss" scoped>

	.score {	
		width: 100%;
		height: 100%;
		margin-top: -60%;
	}
</style>